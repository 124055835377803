
@media all and (min-width: 2000px) {
  .mainContainer {
    max-width: 1410px !important;
  }
}
@media all and (max-width: 1999px) {
  .mainContainer {
    max-width: 100% !important;
    padding-left: 38px !important;
    padding-right: 36px !important;
  }
}

@media all and (min-width: 991px) {
  .mainContainerCheckout {
    max-width: 1410px !important;
    padding-bottom: 60px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .mainContainer {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  table.MuiTable-root th.MuiTableCell-root{
    min-width: 105px;
  }
}

#nextBtn:hover img.select {
  display: none;
}

.unselect {
  display: none;
}

#nextBtn:hover .unselect {
  display: block;
}

.MuiFormLabel-root.Mui-focused,
.MuiRadio-colorSecondary.Mui-checked {
  color: #045098 !important;
}
.MuiAccordionSummary-root {
  background-color: unset !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.slick-slide img {
  object-fit: cover;
}
.themeBtn{
  color: #fff !important;
    cursor: pointer !important;
    height: 45px !important;
    display: flex !important; 
    background: #FF7B28 !important;
    margin-top: 10px !important;
    border-radius: 20px !important;
    flex-direction: column !important;
    text-transform: capitalize !important;
    padding: 0px 20px !important;
    margin-bottom: 12px !important;
    border: 1px solid #FF7B28 !important;
}
.themeBtn:hover{
  color: #000 !important;
    border: 1px solid #FF7B28 !important;
    background-color: #fff !important;
}


.cancelbtn{
  color: black !important;
    cursor: pointer !important;
    height: 40px !important;
    display: flex !important; 
    background: white !important;
    margin-top: 10px !important;
    border-radius: 50px !important;
    flex-direction: column !important;
    text-transform: capitalize !important;
    padding: 0px 20px !important;
    margin-bottom: 12px !important;
    border: 1px solid #FF7B28 !important;
}
.cancelbtn:hover{
  color: #000 !important;
    border: 1px solid #FF7B28 !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
}
.weeklyPop th, .weeklyPop td {
  padding: 8px 5px;
}
.slick-next:before, .slick-prev:before {
  background-color: #000;
  border-radius: 50px;
  padding-top: 2px;
}

/* Scroll bar style inside upload multiple images  */

.uploadMultipleImages ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.uploadMultipleImages ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

.uploadMultipleImages ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #FF7B28;
}

.scrollBarHide::-webkit-scrollbar {
  display: none;
}