body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 400;
  font-style: normal;
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
p, 
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
label,
textarea {
  font-weight: 400;
  font-style: normal;
  /* font-family: "semplicitapro", sans-serif !important; */
  font-family: "Poppins", "Arial", "sans-serif" !important;
}

input{
color: #1F1F1F !important;
border-radius: 15px;
line-height: 1.25rem !important;
font-size: 0.875rem !important;
letter-spacing: 0.14em !important;
font-weight: 400 !important;
}

.MuiInputLabel-formControl{
color: #747775 !important;
letter-spacing: 0.14em !important;
line-height: 1.25rem !important;
font-size: 0.875rem !important;
font-weight: 400 !important;
transform: translate(14px, 14px) scale(1) !important;
/* transform: translate(15px, 16.5px) scale(1) !important; */
}

.MuiFormLabel-filled.MuiInputLabel-root, .Mui-focused.MuiInputLabel-root{
  font-size: 1rem !important;
  background-color: #fff;
  padding-right: 9px;
  transform: translate(14px, -9px) scale(0.75) !important;
}

.MuiInputBase-formControl{
border-radius: 25px !important;
}

.MuiSwitch-switchBase.Mui-checked{
  transform: translateX(15px) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
  border-radius: 25px !important;
  height: 48px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
  border-radius: 25px !important;
  height: 48px;
}

.MuiTableCell-root{
font-family: "Poppins", "Arial", "sans-serif" !important;
}

.MuiButton-root.MuiButton-contained
/* , .MuiButtonBase-root.MuiButton-root.MuiButton-text */
{
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 45px !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.MuiTableCell-root.MuiTableCell-head{
  font-family: "Poppins", "Arial", "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.MuiTableRow-root .MuiTypography-body1{
  font-family: "Poppins", "Arial", "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.MuiSwitch-track{
  opacity: 1 !important;
  height: 26px !important;
  border-radius: 30px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialog-paperWidthSm {
  overflow-x: hidden !important;
}

.slick-dots li button:before {
  color: #fff !important;
  font-size: 8px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}



span.MuiButton-label {
  font-weight: 600;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.Mui-focused {
  /* border-color: #ff7b28 !important ; */
  border-color: #045098 !important ;
  border-width: 3px;
  border-radius: 25px;
  }


/* start ::  style to scrollbar */

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #FF7B28;
}


/* end :: style to scrollbar  */

/* hide arrow from input type number  */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}